export const actionTypes = {
	CANCEL_TRANSACTION: 'pay/CANCEL_TRANSACTION',
	CANCEL_TRANSACTION_COMPLETE: 'pay/CANCEL_TRANSACTION_COMPLETE',
	GET_PAYMENT_STATUS: 'pay/GET_PAYMENT_STATUS',
	GET_PAYMENT_STATUS_COMPLETE: 'pay/GET_PAYMENT_STATUS_COMPLETE',
	GET_TRANSACTION: 'pay/GET_TRANSACTION',
	GET_TRANSACTION_COMPLETE: 'pay/GET_TRANSACTION_COMPLETE',
	NOTIFY_PAYMENT_SUBMITTED: 'pay/NOTIFY_PAYMENT_SUBMITTED',
	NOTIFY_PAYMENT_SUBMITTED_COMPLETE: 'pay/NOTIFY_PAYMENT_SUBMITTED_COMPLETE'
};

export const actions = {
	cancelTransaction: payload => ({
		type: actionTypes.CANCEL_TRANSACTION,
		payload
	}),
	getPaymentStatus: payload => ({
		type: actionTypes.GET_PAYMENT_STATUS,
		payload
	}),
	getTransaction: payload => ({
		type: actionTypes.GET_TRANSACTION,
		payload
	}),
	notifyPaymentSubmitted: payload => ({
		type: actionTypes.NOTIFY_PAYMENT_SUBMITTED,
		payload
	})
};

export default function pay(
	state = {
		paymentStatus: false,
		transaction: undefined,
		isTransactionCanceled: false
	},
	action
) {
	if (!action) return state;

	const nextState = Object.assign({}, state);
	switch (action.type) {
		case actionTypes.CANCEL_TRANSACTION:
			nextState.isTransactionCanceled = false;
			break;
		case actionTypes.CANCEL_TRANSACTION_COMPLETE:
			nextState.isTransactionCanceled = true;
			break;
		case actionTypes.GET_PAYMENT_STATUS_COMPLETE:
			nextState.paymentStatus = action.payload;
			break;
		case actionTypes.GET_TRANSACTION_COMPLETE:
		case actionTypes.NOTIFY_PAYMENT_SUBMITTED_COMPLETE:
			nextState.transaction = action.payload;
			break;
		default:
			return state;
	}
	return nextState;
}
