import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors } from 'theme';

const sections = {
	root: styled.button`
		background-color: ${colors.blue};
		border: 0;
		border-radius: 0.6rem;
		cursor: pointer;
		font-size: 1.8rem;
		font-weight: 400;
		color: #ffffff;
		margin-top: 1rem;
		padding: 1.5rem 5.5rem;
		text-transform: uppercase;

		min-width: 22rem;

		&.bordered {
			border: 2px solid #003974;
		}

		@media only screen and (max-width: 420px) {
			min-width: 16rem;
			padding: 1.5rem 2rem;
		}
	`,
	link: styled(Link)`
		display: block;

		background-color: ${colors.blue};
		border: 0;
		color: #ffffff;
		cursor: pointer;
		font-size: 1.6rem;
		font-weight: 700;
		color: #003974;
		margin-top: 1rem;
		padding: 1.2rem;
		text-transform: uppercase;
		text-decoration: none;
	`,
};

export const Button = props => {
	const {
 border, children, className, type, onClick, to,
} = props;

	if (to) {
		return (
			<sections.link className={border ? 'bordered' : ''} to={to}>
				{children}
			</sections.link>
		);
	}

	return (
		<sections.root
			className={`${className || ''} ${border ? 'bordered' : ''}`}
			type={type}
			onClick={onClick}
		>
			{children}
		</sections.root>
	);
};
