export const actionTypes = {
	APP_INIT: 'app/APP_INIT',
	APP_INIT_COMPLETE: 'app/APP_INIT_COMPLETE',
	APP_TOKEN_SUCCESS: 'app/APP_TOKEN_SUCCESS',
	OPEN_MOBILE_MENU: 'app/OPEN_MOBILE_MENU',
	CLOSE_MOBILE_MENU: 'app/CLOSE_MOBILE_MENU',
	SHOW_LOADING: 'app/SHOW_LOADING'
};

export const actions = {
	init: payload => ({
		type: actionTypes.APP_INIT,
		payload
	}),
	openMobileMenu: () => ({
		type: actionTypes.OPEN_MOBILE_MENU
	}),
	closeMobileMenu: () => ({
		type: actionTypes.CLOSE_MOBILE_MENU
	}),
	showLoading: payload => ({
		type: actionTypes.SHOW_LOADING,
		payload
	})
};

export default function app(
	state = {
		initComplete: false,
		isMobileMenuOpen: false,
		isLoading: false
	},
	action
) {
	const nextState = Object.assign({}, state);
	switch (action.type) {
		case actionTypes.APP_INIT:
			break;
		case actionTypes.APP_INIT_COMPLETE:
			nextState.initComplete = true;
			break;
		case actionTypes.OPEN_MOBILE_MENU:
			nextState.isMobileMenuOpen = true;
			break;
		case actionTypes.CLOSE_MOBILE_MENU:
			nextState.isMobileMenuOpen = false;
			break;
		case actionTypes.SHOW_LOADING:
			nextState.isLoading = action.payload;
			break;
		default:
			return state;
	}
	return nextState;
}
