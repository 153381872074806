import uuid from 'uuid';

export const actionTypes = {
	ADD_NOTIFICATION: 'notifications/ADD',
	REMOVE_NOTIFICATION: 'notifications/REMOVE'
};

export const actions = {
	addNotification: payload => ({
		type: actionTypes.ADD_NOTIFICATION,
		payload
	}),
	removeNotification: payload => ({
		type: actionTypes.REMOVE_NOTIFICATION,
		payload
	})
};

export default function notification(state = { notifications: [] }, action) {
	const nextState = Object.assign({}, state);

	switch (action.type) {
		case actionTypes.ADD_NOTIFICATION: {
			const nextNotification = action.payload;
			nextNotification.id = uuid.v4();

			if (nextNotification.error) {
				nextState.errors = [
					...nextState.errors,
					nextNotification.error
				];
			}

			nextState.notifications = [
				...nextState.notifications,
				nextNotification
			];

			break;
		}
		case actionTypes.REMOVE_NOTIFICATION: {
			nextState.notifications = nextState.notifications.filter(
				n => n.id && n.id !== action.payload.id
			);

			break;
		}
		default:
			return nextState;
	}
	return nextState;
}
