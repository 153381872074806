import React, { useEffect } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import styled from 'styled-components';
import queryString from 'query-string';

import { actions } from 'state/actions/pay.actions';

import { Button } from 'ui-kit/button/button.component';
import { colors } from 'theme';

import { TransactionDetails } from 'components/transaction-details/transaction-details.component';

import { Loading } from 'ui-kit/loading/loading.component';

const sections = {
	root: styled.div``,
	content: styled.div`
		h5 {
			font-size: 1.5rem;
			font-weight: 400;
			margin-top: 0;
		}
		@media only screen and (max-width: 420px) {
		}
	`,
	actions: styled.div`
		display: flex;
		justify-content: flex-end;
	`,
	doneButton: styled(Button)`
		background-color: ${colors.darkGray} !important;

		@media only screen and (max-width: 420px) {
			min-width: 100%;
		}
	`,
	actionsWrapper: styled.div`
		display: flex;
		flex-direction: column;
		padding: 0 4rem;
	`,
	printLink: styled.a`
		font-size: 2rem;
		align-self: flex-end;
		margin-bottom: 1rem;
	`
};

const mapStateToProps = state => ({
	transaction: state.pay.transaction,
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			getTransaction: actions.getTransaction,
		},
		dispatch,
	);

export const Receipt = connect(
	mapStateToProps,
	mapDispatchToProps,
)(props => {
	const { getTransaction, transaction } = props;

	const {
		location: { search },
	} = window;
	const qs = queryString.parse(search);
	const { token } = qs;

	useEffect(() => {
		if (!transaction && token) {
			getTransaction(token);
		}
	}, [getTransaction, token, transaction]);

	const handlePrint = ev => {
		ev.preventDefault();
		window.print();
	}

	if (!token) {
		return <Redirect to="/pay" />;
	}

	return (
		<sections.root>
			<sections.content>
				{!transaction && <Loading />}
				{transaction && (
					<TransactionDetails transaction={transaction} />
				)}
			</sections.content>
			<sections.actions>
			<sections.actionsWrapper>
					<sections.printLink href="#" onClick={handlePrint}>
						Print Receipt
					</sections.printLink>
					<sections.doneButton onClick={props.handleDone}>
						CLOSE
					</sections.doneButton>
				</sections.actionsWrapper>
			</sections.actions>
		</sections.root>
	);
});
