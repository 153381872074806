import React, { Component } from 'react';
import classNames from 'classnames';
import uuid from 'uuid';

import IntersectionVisible from 'react-intersection-visible';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from 'styled-components';
import { StickyBar } from '../sticky-bar/sticky-bar.component';

const sections = {
	navigation: styled.div`
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: space-between;

		background-color: #fff;
		height: 115px;
		margin: 0 auto;
		padding: 0 5rem 0 4rem;
		box-sizing: border-box;
		position: relative;
		width: 100%;

		@media only screen and (max-width: 768px) {
			svg {
				padding: 2rem;
				position: relative;
				right: 1.2rem;
			}
			.fa-bars {
				font-size: 3rem;
				cursor: pointer;
			}
		}
		@media only screen and (max-width: 420px) {
			padding-right: 1rem;
		}
	`,
	mobileMenu: styled.div`
		display: flex;
		flex-direction: column;

		position: absolute;
		top: 114px;
		left: 0;
		width: 100%;
		max-height: 0;
		overflow: hidden;
		background-color: #fff;

		transition: max-height 140ms ease-in;

		a {
			display: flex;
			justify-content: center;

			color: #333c4e;
			background-color: #f2f1f0;
			border-top: 1px solid #e7e4e2;
			font-size: 14px;
			font-family: 'Open Sans';
			font-style: normal;
			font-weight: 400;
			height: 55px;
			letter-spacing: 0px;
			line-height: 55px;
			padding: 0;
			text-align: left;
			-webkit-font-smoothing: auto;
			width: 100%;

			&:last-child {
				border-bottom: 1px solid #e7e4e2;
			}
			z-index: 99;
		}
		&.mobile-menu-visible {
			max-height: 336px;

			transition: max-height 160ms ease-out;
		}
	`,
	menu: styled.div`
		display: flex;
		align-items: center;
	`,
	overlay: styled.div`
		position: absolute;
		top: 114px;
		left: 0;
		background-color: #000;
		opacity: 0.5;
		height: 100vh;
		max-height: 1200px;
		width: 100%;
		z-index: 98;

		@media only screen and (min-width: 768px) {
			height: 100vh;
		}
	`,
	anchor: styled.a`
		color: #2d396c;
		cursor: pointer;
		font-family: 'Open Sans', Arial, Helvetica, sans-serif;
		font-weight: 300;
		font-size: 2.8rem;
		letter-spacing: 1px;
		line-height: 1;
		padding-left: 4rem;
		font-style: normal;
		box-sizing: border-box;
		text-decoration: none;

		@media only screen and (max-width: 420px) {
			font-size: 1.6rem;
		}
		@media only screen and (max-width: 920px) {
			font-size: 2rem;
		}
	`,
};

export class Navigation extends Component {
	state = {
		isNavOnScreen: true,
		isMobileMenuOpen: false,
		currentWidth: null,
	};

	componentDidMount() {
		window.addEventListener('resize', this.updateSize.bind(this));

		this.setState({ currentWidth: window.innerWidth });
	}

	componentWillUnmount() {
		window.addEventListener('resize', this.updateSize.bind(this));
	}

	updateSize() {
		this.setState({ currentWidth: window.innerWidth });
	}

	render() {
		const {
 className, paths, logo, siteUrl,
} = this.props;

		const { currentWidth, isMobileMenuOpen, isNavOnScreen } = this.state;
		const isMobileDevice = currentWidth <= 720;

		const renderNav = () => {
			const navigationClasses = classNames(className, 'navigation', {
				'mobile-menu': isMobileDevice,
			});

			const navigationMenuClasses = classNames({
				'mobile-menu-visible': isMobileMenuOpen,
			});

			return (
				<sections.navigation className={navigationClasses}>
					<a href={siteUrl}>{logo}</a>
					{isMobileDevice && (
						<>
							<FontAwesomeIcon
								icon="bars"
								className="fa-bars"
								onClick={() =>
									this.setState({
										isMobileMenuOpen: !isMobileMenuOpen,
									})}
							/>
							<sections.mobileMenu
								className={navigationMenuClasses}
							>
								{paths &&
									paths.map(p => (
										<sections.anchor
											className={
												p.className ? p.className : ''
											}
											key={uuid.v4()}
											href={p.url}
										>
											{p.label}
										</sections.anchor>
									))}
							</sections.mobileMenu>
							{isMobileMenuOpen && <sections.overlay />}
						</>
					)}
					{!isMobileDevice && (
						<sections.menu className={navigationMenuClasses}>
							{paths &&
								paths.map(p => (
									<sections.anchor
										className={
											p.className ? p.className : ''
										}
										key={uuid.v4()}
										href={p.url}
									>
										{p.label}
									</sections.anchor>
								))}
						</sections.menu>
					)}
				</sections.navigation>
			);
		};

		return (
			<>
				{!isMobileDevice && (
					<>
						<IntersectionVisible
							onShow={() =>
								this.setState({ isNavOnScreen: true })}
							onHide={() =>
								this.setState({ isNavOnScreen: false })}
						>
							{renderNav()}
						</IntersectionVisible>
						<StickyBar isVisible={!isNavOnScreen}>
							{renderNav()}
						</StickyBar>
					</>
				)}
				{isMobileDevice && <>{renderNav()}</>}
			</>
		);
	}
}
