import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import queryString from 'query-string';
import styled from 'styled-components';
import uuid from 'uuid';

import { Dialog, DialogContent } from '@material-ui/core';

import { PolicyDetails } from 'components/policy-details/policy-details.component';
import { PaymentType } from 'components/payment-type/payment-type.component';

import { CoverageOption } from 'components/coverage-option/coverage-option.component';
import { actions } from 'state/actions/bill.actions';

import { Button } from 'ui-kit/button/button.component';
import { Loading } from 'ui-kit/loading/loading.component';

import { colors } from 'theme';

import isArray from 'lodash/isArray';
import isNumber from 'lodash/isNumber';

const {
	_env_: { REACT_APP_PAYGOV_SUCCESS_URL, REACT_APP_PAYGOV_CANCEL_URL },
} = window;

const sections = {
	root: styled.div``,
	title: styled.h1`
		margin: 4rem;
		font-size: 4rem;
	`,
	noPayAlert: styled.div`
		margin: 2rem;
		padding: 2rem;
		background-color: #ff9692;
		color: #fff;
		strong {
			font-size: 3rem;
		}
		p {
			font-size: 1.5rem;
		}
	`,
	policy: styled.div`
		color: #fff;

		padding: 2rem 4rem 4rem 4rem;
	`,
	paymentOptions: styled.div``,
	heading: styled.h3`
		color: #003974;

		@media only screen and (max-width: 420px) {
			margin: 2rem 0 2rem 2rem;
		}
	`,
	coverages: styled.div`
		display: flex;
		padding: 2rem 4rem 4rem 4rem;

		@media only screen and (max-width: 420px) {
			flex-direction: column;
			align-items: center;
		}
	`,
	payDialog: styled(Dialog)`
		.MuiDialog-paper {
			border-radius: 1rem;
			max-width: 92rem;
			padding: 3rem 2rem;
		}

		@media only screen and (max-width: 420px) {
			.MuiDialogContent-root {
				padding: 0;

				&:first-child {
					padding-top: 0;
				}
			}
		}
	`,
	payCoverageDialogContent: styled.div`
		display: flex;
		flex-direction: column;

		h5 {
			font-size: 2rem;
			font-weight: 300;
			margin: 0 0 3rem 0;
		}
		p {
			font-size: 1.5rem;
		}
		strong {
			font-size: 2rem;
			font-weight: 500;
			margin: 3rem 0;
		}
	`,
	modalActions: styled.div`
		display: flex;
		justify-content: flex-end;

		@media only screen and (max-width: 420px) {
			justify-content: center;
		}
	`,
	cancelButton: styled(Button)`
		background-color: ${colors.darkGray};
		margin-right: 2rem;
	`,
};

const mapStateToProps = state => ({
	bill: state.bill,
	payGov: state.bill.payGov,
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			getBill: actions.getBill,
			payBill: actions.payBill,
		},
		dispatch,
	);

export const Bill = connect(
	mapStateToProps,
	mapDispatchToProps,
)(props => {
	const {
 bill, getBill, payBill, payGov, paymentUrl,
} = props;

	const [selectedCoverage, setSelectedCoverage] = useState(undefined);
	const [paymentType, setPaymentType] = useState('PLASTIC_CARD');
	const [
		isStartPayCoverageDialogOpen,
		setIsStartPayCoverageDialogOpen,
	] = useState(false);

	//	query string values
	const {
		location: { search },
	} = window;
	const qs = queryString.parse(search);
	const { email, policyNumber, policyZipCode } = qs;

	//	deep linking, request bill
	useEffect(() => {
		if (!bill.renewalId && policyNumber && policyZipCode) {
			getBill({ policyNumber, policyZipCode });
		}
	}, [bill, getBill, policyNumber, policyZipCode]);

	// send the user to paygov
	if (payGov && payGov.paymentPageUrl) {
		window.location.href = payGov.paymentPageUrl;
		return null;
	}

	const renewalOptionsTitles = [
		'Inflation Adjusted Coverage',
		'Current Policy',
	];
	let { renewalOptions } = bill;
	if (isArray(renewalOptions) && renewalOptions.length > 0) {
		const [firstRenewal, secondRenewal] = renewalOptions;
		// If the total premiums are the same, we only show one option
		if (
			secondRenewal &&
			firstRenewal.totalPremium === secondRenewal.totalPremium
		) {
			renewalOptions = [firstRenewal];
		} else {
			// Sort the renewal options from highest premium value to lowest
			renewalOptions = renewalOptions
				.filter(
					opt =>
						opt &&
						isNumber(opt.totalPremium) &&
						opt.totalPremium > 0,
				)
				.sort((prev, next) => next.totalPremium - prev.totalPremium);
		}
	}

	if (paymentUrl) {
		return <Redirect to="/paid" />;
	}
	//	if no querystring params are available we need to redirect to bill lookup on /pay
	if (!policyNumber || !policyZipCode) return <Redirect to="/pay" />;

	const handlePayCoverageOptionClick = coverage => {
		setSelectedCoverage(coverage);
		setIsStartPayCoverageDialogOpen(true);
	};
	const handleContinueToPaygovClick = () => {
		if (!selectedCoverage) return;

		const paymentDetails = {
			email,
			selectedCoverage,
			paymentType,
			successUrl: REACT_APP_PAYGOV_SUCCESS_URL,
			cancelUrl: REACT_APP_PAYGOV_CANCEL_URL,
			...bill,
		};

		setIsStartPayCoverageDialogOpen(false);
		payBill(paymentDetails);
	};

	const buildRenewalOptionTitleText = (options, i) => {
		if (options.length === 1) {
			return '';
		}
		return `Option ${i + 1}: ${renewalOptionsTitles[i] || ''}`;
	};

	return (
		<sections.root>
			<sections.title>Make a Payment</sections.title>
			<Loading />
			<sections.payDialog
				open={isStartPayCoverageDialogOpen}
				onClose={() => setIsStartPayCoverageDialogOpen(false)}
			>
				<DialogContent>
					<sections.payCoverageDialogContent>
						<h5>
							You have chosen to pay
							{' '}
							<strong>
								$
								{selectedCoverage?.totalPremium}
							</strong>
							{' '}
							premium for Current Coverage
						</h5>
						<PaymentType
							onChange={method => setPaymentType(method)}
						/>
						<strong>
							You will be redirected to Pay.gov to make your
							payment
						</strong>
					</sections.payCoverageDialogContent>
					<sections.modalActions>
						<sections.cancelButton
							onClick={() =>
								setIsStartPayCoverageDialogOpen(false)}
						>
							CANCEL
						</sections.cancelButton>
						<Button onClick={handleContinueToPaygovClick}>
							CONTINUE
						</Button>
					</sections.modalActions>
				</DialogContent>
			</sections.payDialog>
			<sections.policy>
				<PolicyDetails bill={bill} />
			</sections.policy>
			{bill.paymentAllowed ? (
				<sections.coverages>
					{renewalOptions &&
						renewalOptions.map((c, i) => (
							<CoverageOption
								key={uuid.v4()}
								label={buildRenewalOptionTitleText(
									renewalOptions,
									i,
								)}
								coverage={c}
								onClick={opt =>
									handlePayCoverageOptionClick(opt)}
							/>
						))}
				</sections.coverages>
			) : (
				<sections.noPayAlert>
					<strong>
						Your policy is no longer eligible for online payment
					</strong>
					<p>
						Please contact your agent for assistance or call
						866-667-9739 for customer service
					</p>
				</sections.noPayAlert>
			)}
		</sections.root>
	);
});
