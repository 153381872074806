import { call, put, takeLatest } from 'redux-saga/effects';

import { actions } from 'state/actions/app.actions';
import { actionTypes } from 'state/actions/pay.actions';

import PaymentService from 'services/payment.service';

function* cancelTransaction(action) {
	yield put(actions.showLoading(true));

	try {
		const response = yield call(PaymentService.cancelTransaction, {
			token: action.payload
		});

		if (!response.error) {
			yield put({
				type: actionTypes.CANCEL_TRANSACTION_COMPLETE,
				payload: response.data
			});
		} else {
			throw response.error;
		}
	} catch (err) {
		console.log(err);
	}

	yield put(actions.showLoading(false));
}

function* getTransaction(action) {
	yield put(actions.showLoading(true));

	try {
		const response = yield call(PaymentService.getTransaction, {
			token: action.payload
		});

		if (!response.error) {
			yield put({
				type: actionTypes.GET_TRANSACTION_COMPLETE,
				payload: response.data
			});
		} else {
			throw response.error;
		}
	} catch (err) {
		console.log(err);
	}

	yield put(actions.showLoading(false));
}

function* notifyPaymentSubmitted(action) {
	yield put(actions.showLoading(true));

	try {
		const response = yield call(PaymentService.completeTransaction, {
			token: action.payload
		});

		if (!response.error) {
			yield put({
				type: actionTypes.NOTIFY_PAYMENT_SUBMITTED_COMPLETE,
				payload: response.data
			});
		} else {
			throw response.error;
		}
	} catch (err) {
		console.log(err);
	}

	yield put(actions.showLoading(false));
}

export default function* billSaga() {
	yield takeLatest(actionTypes.CANCEL_TRANSACTION, cancelTransaction);
	yield takeLatest(actionTypes.GET_TRANSACTION, getTransaction);
	yield takeLatest(
		actionTypes.NOTIFY_PAYMENT_SUBMITTED,
		notifyPaymentSubmitted
	);
}
