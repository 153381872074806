import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import rootSaga from 'state/root-saga';
import createRootReducer from 'state/root-reducer';

const sagaMiddleware = createSagaMiddleware();

const createStoreInstance = (initialValues = {}) => {
	const middlewares = [sagaMiddleware];
	if (process.env.NODE_ENV === 'development') {
		const logger = createLogger({
			collapsed: true,
			// predicate: (getState, action) =>
		});

		middlewares.push(logger);
	}

	const store = createStore(
		createRootReducer,
		initialValues,
		compose(applyMiddleware(...middlewares)),
	);

	sagaMiddleware.run(rootSaga);

	return store;
};
const store = createStoreInstance();
export default store;
export { createStoreInstance };
