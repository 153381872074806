import React from 'react';

import styled from 'styled-components';

import BillLookup from 'connected-components/bill-lookup/bill-lookup.component';

const sections = {
	root: styled.div`
		display: flex;
		justify-content: space-between;

		background-color: #003974;
		background-image: url(/assets/images/earth-left.png);
		background-repeat: no-repeat;
		background-size: cover;
		color: #fff;
		padding: 2.5rem 5rem;
		min-height: 548px;
		box-sizing: border-box;

		@media only screen and (max-width: 420px) {
			flex-direction: column;

			padding: 0 2rem 5rem 2rem;
			align-items: center;
		}
	`,
	pageSplash: styled.div`
		h1 {
			font-size: 9rem;
		}
		h2 {
			font-size: 5rem;
			font-weight: 300;
			width: 50rem;
		}

		@media only screen and (max-width: 420px) {
			h1 {
				font-size: 4rem;
			}
			h2 {
				font-size: 3rem;
				font-weight: 300;
				width: auto;
			}
		}
	`,
};

export const Pay = () => (
	<sections.root>
		<sections.pageSplash>
			<h1>Make a Payment</h1>
			<h2>Please provide your policy number and property zip code to pay your bill</h2>
		</sections.pageSplash>
		<BillLookup title="Make a Payment" />
	</sections.root>
);
