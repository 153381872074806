import React from 'react';

import styled from 'styled-components';

const sections = {
	root: styled.div`
		margin-top: 2rem;
	`,
	header: styled.div`
		margin: 0 0 2rem 0;
	`,
	title: styled.h1`
		font-weight: 400;
		margin: 0;
	`,
	subtitle: styled.h5`
		font-size: 1.5rem;
		font-weight: 300;
		margin: 0;
	`,
	panel: styled.div`
		display: inline-block;
		position: relative;

		@media only screen and (max-width: 420px) {
			width: 100%;
		}
	`,
	contentVisualEnhancer: styled.div`
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;

		background-color: #fff;
		border-radius: 1rem;
		height: 100%;
		width: 100%;
		opacity: 0.7;
	`,
	content: styled.div`
		position: relative;
		z-index: 2;
		box-sizing: border-box;
		padding: 4rem;
	`,
};

export const OpaquePanel = props => {
	const {
 children, className, title, subTitle,
} = props;

	return (
		<sections.root>
			<sections.header>
				<sections.title>{title}</sections.title>
				{subTitle && <sections.subtitle>{subTitle}</sections.subtitle>}
			</sections.header>
			<sections.panel>
				<sections.contentVisualEnhancer />
				<sections.content className={className}>
					{children}
				</sections.content>
			</sections.panel>
		</sections.root>
	);
};
