import axios from 'axios';

import { PaymentApi } from 'generated-api/renewal-api/api';

import { apiPaths } from 'constants/api';

const {
	_env_: { REACT_APP_BASE_API_URL },
} = window;

/**
 * Anything related to Premiums in the system.
 */
export default class PaymentService {
	static cancelTransaction({ token }) {
		const client = axios.create();

		const api = new PaymentApi(
			undefined,
			`${REACT_APP_BASE_API_URL}${apiPaths.RENEWAL}`,
			client,
		);
		const response = api.cancelTransaction({ token });

		return response;
	}

	static getTransaction({ token }) {
		const client = axios.create();

		const api = new PaymentApi(
			undefined,
			`${REACT_APP_BASE_API_URL}${apiPaths.RENEWAL}`,
			client,
		);
		const response = api.transactionStatus({ token });

		return response;
	}

	static completeTransaction({ token }) {
		const client = axios.create();

		const api = new PaymentApi(
			undefined,
			`${REACT_APP_BASE_API_URL}${apiPaths.RENEWAL}`,
			client,
		);
		const response = api.completeTransaction({ token });

		return response;
	}
}
