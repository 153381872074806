import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { Error } from 'ui-kit/error/error.component';

const sections = {
	root: styled.div`
		display: flex;
		flex-direction: column;
		align-items: center;
		align-content: center;
		justify-content: center;

		pointer-events: none;
		width: 100%;
	`,
	validation: {
		ul: styled.ul`
			padding: 0 0 0 0rem;
		`,
	}
};

const buildValidationErrorsList = (errors, touched) => {
	const errorsList = [];
	if (!errors || Object.keys(errors).length === 0) { return errorsList; }

	for (const key in errors) {
		if (touched[key]) {
			errorsList.push(errors[key]);
		}
	}

	return errorsList;
};

const emptyList = [];
export const ValidationErrors = props => {
	const { errors, touched, title = 'Errors', allowCloseOption } = props;
	const [ validationErrors, setValidationErrors ] = useState(emptyList);

	useEffect(() => {
		const errorsList = buildValidationErrorsList(errors, touched);
		if (errorsList.length === 0) {
			setValidationErrors(emptyList);
			return;
		}

		setValidationErrors(errorsList);
	}, [errors, touched]);

	return (
		<sections.root className="error-system">
			{
				validationErrors.length > 0 &&
				<Error
					level="critical"
					stayVisible={true}
					title={title}
					allowCloseOption={allowCloseOption}
				>
					{
						validationErrors.length > 0 &&
						<sections.validation.ul>
							{
								validationErrors.map(validationMessage => (
									<li key={validationMessage}>{validationMessage}</li>
								))
							}
						</sections.validation.ul>
					}
				</Error>
			}
		</sections.root>
	);
};
