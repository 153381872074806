import React from 'react';

import styled from 'styled-components';

const sections = {
	root: styled.div`
		color: #000000;
		max-width: 72rem;
	`,
	title: styled.h1`
		font-weight: 500;
		margin-bottom: 4rem;
	`,
	grid: styled.div`
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;

		background-color: #f5f5f5;
		padding: 10px;
	`,
	column: styled.div`
		@media only screen and (max-width: 420px) {
			margin: 0;
		}
	`,
	row: styled.div`
		box-sizing: border-box;
		padding: 1rem 0 1.5rem 0;
	`,
	policyDetail: styled.div`
		display: flex;
		flex-direction: row;
		font-size: 2rem;
		font-weight: 300;

		label {
			font-weight: 500;
			width: 20rem;
		}
		p {
			margin: 0;
			padding: 0 2rem;
		}

		@media only screen and (max-width: 420px) {
			flex-direction: column;

			label {
				width: auto;
			}
			p {
				padding: 0;
			}
		}
	`,
};

export const PolicyDetails = props => {
	const { bill } = props;

	if (!bill) return null;

	const {
		policyNumber,
		policyStartDate,
		policyEndDate,
		propertyAddressLine1,
		propertyAddressLine2,
		propertyCity,
		propertyStateCode,
		propertyPostalCode,
		insuredName,
	} = bill;

	return (
		<sections.root>
			<sections.title>Policy Info</sections.title>
			<sections.grid>
				<sections.row>
					<sections.column>
						<sections.policyDetail>
							<label>Insured Name:</label>
							<p>{insuredName}</p>
						</sections.policyDetail>
					</sections.column>
				</sections.row>
				<sections.row>
					<sections.column>
						<sections.policyDetail>
							<label>Property Address:</label>
							<p>
								{propertyAddressLine1}
								{' '}
								{propertyAddressLine2}
								,
								{' '}
								{propertyCity}
								{' '}
								{propertyStateCode}
								{' '}
								{propertyPostalCode}
							</p>
						</sections.policyDetail>
					</sections.column>
				</sections.row>
				<sections.row>
					<sections.column>
						<sections.policyDetail>
							<label>Policy Number:</label>
							<p>{policyNumber}</p>
						</sections.policyDetail>
					</sections.column>
				</sections.row>
				<sections.row>
					<sections.column>
						<sections.policyDetail>
							<label>Policy Period:</label>
							<p>
								{policyStartDate}
								{' '}
								to
								{' '}
								{policyEndDate}
							</p>
						</sections.policyDetail>
					</sections.column>
				</sections.row>
			</sections.grid>
		</sections.root>
	);
};
