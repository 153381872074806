import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Redirect } from 'react-router-dom';

import styled from 'styled-components';
import queryString from 'query-string';

import { actions } from 'state/actions/pay.actions';

import { Loading } from 'ui-kit/loading/loading.component';

const sections = {
	root: styled.div``,
};

const mapStateToProps = state => ({
	isTransactionCanceled: state.pay.isTransactionCanceled,
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			cancelTransaction: actions.cancelTransaction,
		},
		dispatch,
	);

export const Cancel = connect(
	mapStateToProps,
	mapDispatchToProps,
)(props => {
	const { cancelTransaction, isTransactionCanceled } = props;
	const [policyZip, setPolicyZip] = useState(undefined);
	const [policyNum, setPolicyNum] = useState(undefined);

	useEffect(() => {
		const {
			location: { search },
		} = window;
		const qs = queryString.parse(search);
		const { policyNumber, policyZipCode, token } = qs;

		setPolicyNum(policyNumber);
		setPolicyZip(policyZipCode);

		if (token) cancelTransaction(token);
	}, [cancelTransaction]);

	if (isTransactionCanceled) {
		return (
			<Redirect
				to={`/pay/bill?policyNumber=${policyNum}&policyZipCode=${policyZip}`}
			/>
		);
	}

	return (
		<sections.root>
			<Loading />
		</sections.root>
	);
});
