import React from 'react';

import styled from 'styled-components';
import Numeral from 'numeral';

const sections = {
	root: styled.div`
		display: flex;
		flex-direction: column;
		padding: 0 4rem;
	`,
	notification: styled.div`
		margin: 2rem 0;
		padding: 2rem;
		background-color: #eafaf2;
		color: #000;
		font-size: 2rem;
	`,
	grid: styled.div`
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;

		border-top: 1px solid #000000;
		margin-bottom: 4rem;
	`,
	column: styled.div`
		min-width: 22rem;
		padding: 1.6rem 0 1.8rem 0;

		@media only screen and (max-width: 420px) {
			min-width: 18rem;
			padding: 0;
		}
	`,
	row: styled.div`
		border-bottom: 1px solid #000000;
	`,
	policyDetail: styled.div`
		display: flex;
		flex-direction: row;
		font-size: 2rem;

		label {
			font-weight: 500;
		}
		p {
			margin: 0;
			padding: 0 2rem;
		}

		@media only screen and (max-width: 420px) {
			flex-direction: column;
			padding: 1.6rem 0;

			label {
				display: block;
				padding: 0 0 0.4rem 0;
			}
			p {
				padding: 0;
			}
		}
	`,
};

const numeralCurrencyFormatDefault = '$ 0,0[.]00';

export const TransactionDetails = props => {
	const { transaction } = props;

	if (!transaction) return null;

	const {
		amount,
		balance,
		transactedAt,
		approvalCode,
		insuredName,
		paymentType,
		policyNumber,
		policyStartDate,
		policyEndDate,
		propertyAddressLine1,
		propertyAddressLine2,
		propertyCity,
		propertyStateCode,
	} = transaction;

	const paymentMethod =
		paymentType === 'PLASTIC_CARD' ? 'Credit Card' : 'E Check';

	return (
		<sections.root className='printable'>
			<sections.notification>
			<h3>Payment Received</h3>
				<p>
					We have received your
					{' '}
					<strong>
						$
						{amount}
					</strong>
					{' '}
					payment by
					{' '}
					{paymentMethod}
				</p>
			</sections.notification>
			<sections.grid>
				<sections.row>
					<sections.policyDetail>
						<sections.column>
							<label>Insured Name</label>
						</sections.column>
						<sections.column>
							<p>{insuredName}</p>
						</sections.column>
					</sections.policyDetail>
				</sections.row>
				<sections.row>
					<sections.policyDetail>
						<sections.column>
							<label>Property Address</label>
						</sections.column>
						<sections.column>
							<p>
								{propertyAddressLine1}
								{propertyAddressLine2
									? `, ${propertyAddressLine2}`
									: ''}
								,
								{' '}
								{propertyCity}
								{' '}
								{propertyStateCode}
							</p>
						</sections.column>
					</sections.policyDetail>
				</sections.row>
				<sections.row>
					<sections.policyDetail>
						<sections.column>
							<label>Policy Number</label>
						</sections.column>
						<sections.column>
							<p>{policyNumber}</p>
						</sections.column>
					</sections.policyDetail>
				</sections.row>
				<sections.row>
					<sections.policyDetail>
						<sections.column>
							<label>Policy Period</label>
						</sections.column>
						<sections.column>
							<p>
								{policyStartDate}
								{' '}
								to
								{' '}
								{policyEndDate}
							</p>
						</sections.column>
					</sections.policyDetail>
				</sections.row>
				<sections.row>
					<sections.policyDetail>
						<sections.column>
							<label>Amount Paid</label>
						</sections.column>
						<sections.column>
							<p>
								{Numeral(amount).format(
									numeralCurrencyFormatDefault,
								)}
							</p>
						</sections.column>
					</sections.policyDetail>
				</sections.row>
				<sections.row>
					<sections.policyDetail>
						<sections.column>
							<label>Balance</label>
						</sections.column>
						<sections.column>
							<p>
								{Numeral(balance).format(
									numeralCurrencyFormatDefault,
								)}
							</p>
						</sections.column>
					</sections.policyDetail>
				</sections.row>
				<sections.row>
					<sections.policyDetail>
						<sections.column>
							<label>Transaction Date</label>
						</sections.column>
						<sections.column>
							<p>{transactedAt}</p>
						</sections.column>
					</sections.policyDetail>
				</sections.row>
				<sections.row>
					<sections.policyDetail>
						<sections.column>
							<label>Approval Code</label>
						</sections.column>
						<sections.column>
							<p>{approvalCode}</p>
						</sections.column>
					</sections.policyDetail>
				</sections.row>
			</sections.grid>
		</sections.root>
	);
};
