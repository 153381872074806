import React from 'react';
import styled from 'styled-components';

const sections = {
	root: styled.div`
		display: flex;
		flex-direction: column;
		background-color: #fff;
		box-sizing: border-box;
		box-shadow: 0 2px 2px rgba(100, 100, 100, 0.1);
		padding: 0;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 99;

		&.hidden {
			display: none;
		}
	`,
};

export const StickyBar = props => {
	const { children, isVisible } = props;

	if (!isVisible) return <sections.root className="hidden" />;

	return <sections.root>{children}</sections.root>;
};
