import React from 'react';

import { connect } from 'react-redux';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const sections = {
	root: styled.div`
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		background-color: #003974;
		color: #fff;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		opacity: 0.8;

		z-index: 99;

		svg {
			font-size: 3em;
		}
	`,
	cogOnly: styled.div`
		svg {
			margin: 0 1rem;
		}
	`,
};

const mapStateToProps = state => ({
	isLoading: state.app.isLoading,
});
export const Loading = connect(
	mapStateToProps,
	null,
)(props => {
	const { isLoading } = props;

	if (!isLoading) return null;

	return (
		<sections.root>
			<FontAwesomeIcon icon="cog" className="fa-spin" />
			<h1>Loading, please wait..</h1>
		</sections.root>
	);
});
export const LoadingSpinner = connect(
	mapStateToProps,
	null,
)(props => {
	const { className, isLoading, forceShow } = props;

	if (!isLoading && !forceShow) return null;

	return (
		<sections.cogOnly className={className}>
			<FontAwesomeIcon icon="cog" className="fa-spin" />
		</sections.cogOnly>
	);
});
