import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { library as fortAwesomeLibrary } from '@fortawesome/fontawesome-svg-core';
import {
	faBars,
	faCheck,
	faChevronDown,
	faCog,
	faLongArrowAltRight,
	faTimes,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import styled from 'styled-components';

import { Navigation } from 'components/navigation/navigation.component';
import { actions } from 'state/actions/app.actions';

import { colors } from 'theme';

const sections = {
	root: styled.div`
		margin: 0 auto;
		font-size: 1.5rem;
		padding: 0 0 5rem 0;
		box-sizing: border-box;
	`,
	logo: styled.img`
		height: 5rem;
		margin: 1rem 0 0 1rem;

		@media only screen and (max-width: 420px) {
			height: 3.5rem;
			margin: 1rem 0 0 0;
		}
	`,
	navigation: styled(Navigation)`
		.about-us {
			color: ${colors.blue};
		}
	`,
	content: styled.div`
		box-sizing: border-box;

		@media only screen and (max-width: 420px) {
			padding: 0;
		}
	`,
	footer: styled.footer``,
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			init: actions.init,
		},
		dispatch,
	);

class App extends Component {
	constructor(props) {
		super(props);

		fortAwesomeLibrary.add(
			faBars,
			faCheck,
			faChevronDown,
			faCog,
			faLongArrowAltRight,
			faTimes,
		);
	}

	componentDidMount() {
		const { init } = this.props;

		init();
	}

	render() {
		const { children, theme } = this.props;
		const classes = classNames('app', theme);

		document.title = 'nationalfloodservices.com - Pay Your Bill';

		return (
			<sections.root className={classes}>
				<sections.navigation
					logo={<sections.logo src="/assets/images/nfs-logo.png" />}
					paths={[
						{
							className: 'about-us',
							url:
								'https://nationalfloodservices.com/about-us/nfsstory/',
							label: 'About Us',
						},
					]}
				/>
				<sections.content>{children}</sections.content>
			</sections.root>
		);
	}
}

export default connect(undefined, mapDispatchToProps)(App);
