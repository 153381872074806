import uuid from 'uuid';

export const actionTypes = {
	ADD_ERROR: 'errors/ADD',
	REMOVE_ERROR: 'errors/REMOVE'
};

export const actions = {
	addError: payload => ({
		type: actionTypes.ADD_ERROR,
		payload
	}),
	removeError: payload => ({
		type: actionTypes.REMOVE_ERROR,
		payload
	})
};

export default function error(state = { errors: [] }, action) {
	const nextState = Object.assign({}, state);

	switch (action.type) {
		case actionTypes.ADD_ERROR: {
			const nextError = action.payload;

			nextError.id = uuid.v4();
			nextError.addedOn = new Date();

			if (nextError.error) {
				nextState.errors = [...nextState.errors, nextError.error];
			}

			nextState.errors = [...nextState.errors, nextError];

			break;
		}
		case actionTypes.REMOVE_ERROR: {
			nextState.errors = [
				...nextState.errors.filter(
					n => n.id && n.id !== action.payload.id
				)
			];

			break;
		}
		default:
			return nextState;
	}
	return nextState;
}
