import styled from 'styled-components';
import { colors } from 'theme';
import { Button } from '../../ui-kit/button/button.component';
import { LoadingSpinner } from '../../ui-kit/loading/loading.component';

export const sections = {
	root: styled.div`
		display: flex;
		justify-content: center;

		background-color: #003974;
		background-image: url(./assets/images/earth-right.png);
		background-repeat: none;
		background-size: cover;
		color: #fff;

		padding: 22rem 5rem 56rem 5rem;

		@media only screen and (max-width: 420px) {
			padding: 6rem 3rem 8rem 3rem;
		}
	`,
	styledLoadingSpinner: styled(LoadingSpinner)`
		margin: 3rem 0 0 0;
	`,
	heading: styled.div`
		h5 {
			font-size: 1.5rem;
			font-weight: 400;
			margin-top: 0;
		}
		@media only screen and (max-width: 420px) {
		}
	`,
	content: styled.div`
		display: flex;
		flex-direction: column;

		background-color: #ffffff;
		border-radius: 1rem;
		color: #000;
		padding: 2rem 2rem 4rem 2rem;

		@media only screen and (max-width: 420px) {
			padding: 2rem;
		}
	`,
	statusMessage: styled.div`
		display: flex;
		flex-direction: column;
		align-items: center;
		align-content: center;
		justify-content: flex-start;

		h1 {
			font-size: 3rem;
			font-weight: 500;
			margin-bottom: 1rem;
		}
		svg {
			font-size: 4rem;
		}
	`,
	verifyingMessage: styled.h5`
		max-width: 48rem;
		margin-bottom: 2rem;
	`,
	approvalCode: styled.div`
		display: flex;
		align-items: center;

		margin-bottom: 3rem;

		h5 {
			font-size: 2rem;
			margin-right: 8rem;
		}
		span {
			font-size: 2rem;
		}
	`,
	actions: styled.div`
		@media only screen and (max-width: 420px) {
			display: flex;
			flex-direction: column-reverse;
		}
	`,
	doneButton: styled(Button)`
		background-color: ${colors.darkGray} !important;
		margin-right: 2rem;

		@media only screen and (max-width: 420px) {
			min-width: 100%;
		}
	`,
	viewReceiptButton: styled(Button)`
		@media only screen and (max-width: 420px) {
			min-width: 100%;
		}
	`,
};
