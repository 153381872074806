import React from 'react';

import Numeral from 'numeral';
import styled from 'styled-components';

import { colors } from 'theme';
import isString from 'lodash/isString';
import { Button } from '../../ui-kit/button/button.component';

const sections = {
	root: styled.div`
		display: flex;
		flex-direction: column;
		color: #000000;
		width: 70rem;

		@media only screen and (max-width: 420px) {
			margin-bottom: 3rem;
			width: 40rem;
		}
	`,
	header: styled.div`
		display: flex;
		justify-content: space-between;

		h2 {
			margin-right: 20rem;
		}
	`,
	optionLabel: styled.h1`
		font-weight: 500;
	`,
	premium: styled.h1`
		color: ${colors.blue};
		font-weight: 400;
		font-size: 3.5rem;
		margin: 1rem 0 5rem 0;
	`,
	premiumValue: styled.span`
		font-weight: 700;
	`,
	content: styled.div`
		background-color: #f5f5f5;
		margin: 0 2rem 0 0;
		padding: 0.5rem 2rem 2rem 2rem;

		@media only screen and (max-width: 420px) {
			margin: 0;
		}
	`,
	detail: styled.div`
		display: flex;
		font-size: 2.2rem;
		margin-bottom: 3rem;

		label {
			font-weight: 500;
			width: 28rem;
		}
		p {
			margin: 0;
			font-weight: 300;
			padding: 0 2rem;
		}
	`,
	disclaimer: styled.div`
		border-top: 1px solid #000000;
		box-sizing: border-box;
		font-size: 1.5rem;
		font-weight: 300;
		padding-top: 1rem;
		margin-bottom: 3rem;
	`,
	actions: styled.div`
		display: flex;
		justify-content: flex-end;
		align-self: flex-end;

		@media only screen and (max-width: 420px) {
			margin: 0;
		}
	`,
};

const numeralCurrencyFormatDefault = '$0,0[.]00';

export const CoverageOption = props => {
	const { coverage, label, onClick } = props;

	const {
		totalBuildingCoverage,
		buildingDeductible,
		totalContentsCoverage,
		contentDeductible,
		totalPremium,
	} = coverage;

	return (
		<sections.root>
			{isString(label) && label.length > 0 && (
				<sections.optionLabel>{label}</sections.optionLabel>
			)}
			<sections.content>
				<sections.header>
					<sections.premium>
						{'Pay '}
						<sections.premiumValue>
							{Numeral(totalPremium).format(
								numeralCurrencyFormatDefault,
							)}
							{' '}
						</sections.premiumValue>
						{' Premium'}
					</sections.premium>
				</sections.header>
				<sections.detail>
					<label>Building Coverage</label>
					<p>
						{Numeral(totalBuildingCoverage).format(
							numeralCurrencyFormatDefault,
						)}
					</p>
				</sections.detail>
				<sections.detail>
					<label>Building Deductible</label>
					<p>
						{Numeral(buildingDeductible).format(
							numeralCurrencyFormatDefault,
						)}
					</p>
				</sections.detail>
				<sections.detail>
					<label>Contents Coverage</label>
					<p>
						{Numeral(totalContentsCoverage).format(
							numeralCurrencyFormatDefault,
						)}
					</p>
				</sections.detail>
				<sections.detail>
					<label>Contents Deductible</label>
					<p>
						{Numeral(contentDeductible).format(
							numeralCurrencyFormatDefault,
						)}
					</p>
				</sections.detail>
				<sections.disclaimer />
				<sections.actions>
					<Button
						onClick={() =>
							(onClick ? onClick(coverage) : undefined)}
					>
						Select &amp; Pay
					</Button>
				</sections.actions>
			</sections.content>
		</sections.root>
	);
};
