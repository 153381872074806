import React from 'react';
import styled from 'styled-components';

import { useField } from 'formik';

const sections = {
	textLabel: styled.label`
		display: flex;
		color: #000;
		font-size: 1.5em;
		font-weight: 300;
		margin-bottom: 1rem;
	`,
	textInput: styled.input`
		box-shadow: inset 0 5px 5px rgba(125, 125, 125, 0.3);
		font-size: 2.5rem;
		padding: 1.2rem;
		border-radius: 0.5rem;
		border: ${props => props.hasError ? '1px solid #ff0000' : '1px solid #0a0a0a'};
		height: 4.5rem;
		width: 48rem;

		@media only screen and (max-width: 420px) {
			width: auto;
		}
	`,
	textInputWrapper: styled.div`
		display: flex;
		flex-direction: column;

		margin-bottom: 2rem;
		text-align: left;

		@media only screen and (max-width: 420px) {
			width: 100%;
		}
	`,
	validationError: styled.div`
		display: flex;
		color: red;
		background-color: transparent;
		font-size: 2rem;
		font-weight: 400;
		margin: 0.7rem 0;		
	`,
	requiredAsterisk: styled.div`
		color: #ff0000;
		font-size: 1.4rem;
		margin: 0 0.5rem;
	`,
	optionalField: styled.div`
		color: #000;
		font-size: 1.4rem;
		margin: 0 0.5rem;
	`
};

export const TextInput = ({
 id, label, required, ...props
}) => (
	<sections.textInputWrapper>
		<sections.textLabel htmlFor={id}>
			{label}
			{required && (
				<sections.requiredAsterisk>* Required Field</sections.requiredAsterisk>
			)}
		</sections.textLabel>
		<sections.textInput id={id} key={id} {...props} />
	</sections.textInputWrapper>
);

export const TextInputWithValidation = ({
 id, label, required, ...props
}) => {
	const [field, meta] = useField(props);

	return (
		<sections.textInputWrapper>
			<sections.textLabel htmlFor={id}>
				{label}
				{required ? (
					<sections.requiredAsterisk>* Required Field</sections.requiredAsterisk>
				) : (
					<sections.optionalField>(Optional)</sections.optionalField>
				)}
			</sections.textLabel>
			<sections.textInput id={id} key={id} {...field} {...props} />
			{
				props.showInlineError && props.hasError && meta.touched &&
				<sections.validationError>
					{meta.error || ''}
				</sections.validationError>
			}
		</sections.textInputWrapper>
	);
};
