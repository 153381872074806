export const actionTypes = {
	GET_BILL: 'bill/GET_BILL',
	GET_BILL_COMPLETE: 'bill/GET_BILL_COMPLETE',
	PAY_BILL: 'bill/PAY_BILL',
	PAY_BILL_STARTED: 'bill/PAY_BILL_STARTED',
	PAY_BILL_COMPLETE: 'bill/PAY_BILL_COMPLETE'
};

export const actions = {
	getBill: payload => ({
		type: actionTypes.GET_BILL,
		payload
	}),
	payBill: payload => ({
		type: actionTypes.PAY_BILL,
		payload
	})
};

export default function bill(state = {}, action) {
	let nextState = Object.assign({}, state);
	switch (action.type) {
		case actionTypes.GET_BILL_COMPLETE:
			nextState = action.payload;
			break;
		case actionTypes.PAY_BILL_COMPLETE:
			break;
		case actionTypes.PAY_BILL_STARTED:
			nextState.payGov = action.payload;
			break;
		default:
			return state;
	}
	return nextState;
}
