import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { sortBy } from 'lodash';

import styled from 'styled-components';

import { Error } from 'ui-kit/error/error.component';
import { actions } from 'state/actions/error.actions';
import store from 'state/store';

export const ErrorSystemStore = {
	addError: err => store.dispatch(actions.addError(err)),
	removeError: err => store.dispatch(actions.removeError(err)),
};

export const ErrorLevel = {
	Critical: 'critical',
	Debug: 'debug',
	Info: 'info',
	Warn: 'warn',
};

const sections = {
	root: styled.div`
		display: flex;
		flex-direction: column;
		align-items: center;
		align-content: center;
		justify-content: center;

		pointer-events: none;
		width: 100%;
	`,
};

const mapStateToProps = state => ({
	errors: state.error.errors,
});

export const ErrorSystem = connect(
	mapStateToProps,
	null,
)(props => {
	const { errors, latestOnly } = props;
	const [_errors, _setErrors] = useState([]);

	useEffect(() => {
		if (!errors || errors.length === 0) {
			_setErrors([]);
			return;
		}

		if (latestOnly) {
			const nextErrors = sortBy(errors, err => err.addedOn);
			_setErrors([nextErrors[0]]);
		} else {
			_setErrors(errors);
		}
	}, [errors, latestOnly]);

	return (
		<sections.root className="error-system">
			{_errors &&
				_errors.length > 0 &&
				_errors.map(n => (
					<Error
						key={n.id}
						id={n.id}
						level={n.level}
						message={n.message}
						onClose={err => {
							ErrorSystemStore.removeError(err);
							if (n.onClose) n.onClose(err);
						}}
						stayVisible={n.stayVisible}
						title={n.title}
					/>
				))}
		</sections.root>
	);
});
