import React, { useState, useCallback, useEffect } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import queryString from 'query-string';

import { actions as billActions } from '../../state/actions/bill.actions';
import { actions } from '../../state/actions/pay.actions';

import { Receipt } from '../receipt/receipt.component';

import { sections } from './paid.styles';

const mapStateToProps = state => ({
	bill: state.bill,
	transaction: state.pay.transaction,
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			getBill: billActions.getBill,
			getTransaction: actions.getTransaction,
			notifyPaymentSubmitted: actions.notifyPaymentSubmitted,
		},
		dispatch,
	);

export const Paid = connect(
	mapStateToProps,
	mapDispatchToProps,
)(props => {
	const {
		bill,
		getBill,
		getTransaction,
		notifyPaymentSubmitted,
		transaction,
	} = props;

	const [showReceipt, setShowReceipt] = useState(false);

	const {
		location: { search },
	} = window;
	const qs = queryString.parse(search);
	const { token } = qs;

	const handleRetryGetTransaction = useCallback(() => {
		getTransaction(token);
	}, [getTransaction, token]);

	const handleDone = useCallback(() => {
		props.history.push('/');
	}, [props.history]);

	const handleShowReceipt = useCallback(() => {
		setShowReceipt(true);
	}, []);

	useEffect(() => {
		if (!transaction && token) {
			notifyPaymentSubmitted(token);
		}

		//	start a timer and re issue call for transaction if transaction status is UNKNOWN
		if (token && transaction) {
			const { status, paymentStatus } = transaction;
			if (status !== 'SUCCESS' || paymentStatus !== 'SUCCESS') {
				//	requery every 30 seconds
				setTimeout(handleRetryGetTransaction, 30000);
			}
		}
	}, [
		bill,
		getBill,
		getTransaction,
		handleRetryGetTransaction,
		notifyPaymentSubmitted,
		token,
		transaction,
	]);

	// Once the transaction status or payment status is successful, automatically show the receipt
	useEffect(() => {
		if (
			transaction?.status === 'SUCCESS' ||
			transaction?.paymentStatus === 'SUCCESS'
		) {
			handleShowReceipt();
		}
	}, [transaction, handleShowReceipt]);

	if (!token) {
		return <Redirect to="/pay" />;
	}

	return (
		<>
			{!showReceipt && (
				<sections.root>
					<sections.content>
						<sections.heading>
							<sections.statusMessage>
								{(transaction?.status !== 'SUCCESS' ||
									transaction?.paymentStatus ===
										'PENDING') && (
										<>
											<sections.styledLoadingSpinner
												forceShow
											/>
											<h1>
												Thank you! Your payment has been
												received.
												{' '}
											</h1>
										</>
								)}
								{transaction?.status === 'SUCCESS' &&
									transaction?.paymentStatus === 'FAIL' && (
										<>
											<h1>
												There was an issue verifying the
												acceptance of your payment.
											</h1>
										</>
									)}
							</sections.statusMessage>
							{(transaction?.status !== 'SUCCESS' ||
								transaction?.paymentStatus !== 'SUCCESS') && (
								<sections.verifyingMessage>
									We&apos;re verifying that your payment was
									accepted. This page will update when your
									payment acceptance has been confirmed.
								</sections.verifyingMessage>
							)}
						</sections.heading>
						{transaction?.approvalCode && (
							<sections.approvalCode>
								<h5>Approval Code</h5>
								<span>{transaction.approvalCode}</span>
							</sections.approvalCode>
						)}
					</sections.content>
				</sections.root>
			)}

			{showReceipt && (
				<sections.root>
					<sections.content>
						<sections.heading>
							<Receipt handleDone={handleDone} />
						</sections.heading>
					</sections.content>
				</sections.root>
			)}
		</>
	);
});
