import React from 'react';

import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { colors } from 'theme';

const sections = {
	root: styled.div`
		display: flex;
		justify-content: space-between;
		padding: 2rem;
		box-sizing: border-box;

		@media only screen and (max-width: 420px) {
			flex-direction: column;
			padding: 2rem;
		}
	`,
	copyright: styled.div`
		font-size: 2rem;
		font-weight: 300;
	`,
	legal: styled.div`
		display: flex;
		font-size: 2rem;

		a {
			text-decoration: none;
			color: ${colors.blue};
		}

		@media only screen and (max-width: 420px) {
			display: flex;
			margin-top: 1rem;
			font-size: 1.5rem;

			a {
				margin: 0 0 1rem 0;
			}
		}
	`,
	spacer: styled.div`
		font-weight: 500;
		margin: 0 1rem;
	`,
};

export const Footer = () => (
	<sections.root>
		<sections.copyright>
			© National Flood Services LLC 2020
		</sections.copyright>
		<sections.legal>
			<Link to="/privacy-policy">Privacy Policy</Link>
			<sections.spacer>|</sections.spacer>
			<Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
		</sections.legal>
	</sections.root>
);
