import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Formik } from 'formik';

import { ErrorSystem } from 'connected-components/error-system/error-system.component';

import { actions } from 'state/actions/bill.actions';
import { Button } from 'ui-kit/button/button.component';
import { Loading } from 'ui-kit/loading/loading.component';
import { OpaquePanel } from 'ui-kit/opaque-panel/opaque-panel.component';
import { TextInputWithValidation } from 'ui-kit/text-input/text-input.component';
import { ValidationErrors } from 'ui-kit/validation/validation-errors.component';
import { billLookupValidationRules } from './bill-lookup.validation-rules';

const sections = {
	root: styled.div`
		margin-bottom: 3rem;
		position: relative;
		z-index: 0;

		@media only screen and (max-width: 420px) {
			width: 100%;
		}
	`,
	button: styled(Button)`
		display: flex;
		justify-content: space-between;
		align-items: center;
		align-self: flex-end;

		background-color: #f2e56e;
		border-radius: 0.5rem;
		color: #000000;
		font-size: 2.5rem;
		font-weight: 300;
		padding: 1rem 2rem;
		text-transform: none;
		width: 100%;

		svg {
			font-size: 5rem;
		}
	`,
	title: styled.div`
		h1 {
			font-size: 4em;
			margin: 0;
		}
		p {
			font-size: 2rem;
		}
		margin-bottom: 2rem;

		@media only screen and (min-width: 768px) {
			p {
				max-width: 40rem;
			}
		}
	`,
	panel: styled(OpaquePanel)`
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@media only screen and (max-width: 420px) {
			width: 100%;
			padding: 2rem 2rem 6rem 2rem;
		}
	`,
	policyInputs: styled.div`
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		margin: 0;

		svg {
			font-size: 8rem;
		}

		@media only screen and (max-width: 420px) {
			width: auto;
		}
	`,
	requiredInfo: styled.div`
		display: flex;
		color: #000000;
		font-size: 2rem;
		font-weight: 300;
	`,
	requiredAsterisk: styled.div`
		color: #ff0000;
		font-size: 2.8rem;
		margin: 0 0.5rem 0 0;
	`,
};

const mapStateToProps = state => ({
	bill: state.bill,
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			getBill: actions.getBill,
		},
		dispatch,
	);

const BillLookup = props => {
	const { bill, getBill } = props;

	const [email, setEmail] = useState('');
	const [policyNumber, setPolicyNumber] = useState('');
	const [policyZipCode, setPolicyZipCode] = useState('');

	if (bill && bill.renewalId) {
		const to = `/pay/bill?policyNumber=${policyNumber}&policyZipCode=${policyZipCode}`;
		if (email) {
			return <Redirect to={`${to}&email=${email}`} />;
		}

		return <Redirect to={to} />;
	}

	return (
		<Formik
			initialValues={{
				email,
				policyNumber,
				policyZipCode,
			}}
			onSubmit={values => {
				getBill({
					email: values.email,
					policyNumber: values.policyNumber,
					policyZipCode: values.policyZipCode,
				});
			}}
			validationSchema={billLookupValidationRules}
		>
			{({
				handleChange,
				submitForm,
				handleBlur,
				values,
				errors,
				touched,
			}) => (
				<sections.root>
					<sections.panel>
						<Loading />
						<sections.policyInputs>
							<TextInputWithValidation
								name="policyNumber"
								type="text"
								onChange={e => {
									setPolicyNumber(e.target.value);
									handleChange(e);
								}}
								onBlur={handleBlur}
								value={values.policyNumber}
								label="Policy Number"
								hasError={errors.policyNumber}
								showInlineError={false}
								required
							/>
							<TextInputWithValidation
								name="policyZipCode"
								type="text"
								onChange={e => {
									setPolicyZipCode(e.target.value);
									handleChange(e);
								}}
								onBlur={handleBlur}
								value={values.policyZipCode}
								label="Policy Zip Code"
								hasError={errors.policyZipCode}
								showInlineError={false}
								required
							/>
							<TextInputWithValidation
								name="email"
								type="email"
								onChange={e => {
									setEmail(e.target.value);
									handleChange(e);
								}}
								onBlur={handleBlur}
								value={values.email}
								label="Email Address"
								hasError={errors.email}
								showInlineError={false}
							/>
						</sections.policyInputs>
						<ValidationErrors
							errors={errors}
							touched={touched}
							allowCloseOption={false}
						/>
						<ErrorSystem latestOnly />
						<sections.button
							type="button"
							onClick={() => submitForm()}
						>
							Continue
							<FontAwesomeIcon icon="long-arrow-alt-right" />
						</sections.button>
					</sections.panel>
				</sections.root>
			)}
		</Formik>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(BillLookup);
