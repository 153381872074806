import React, { useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import styled from 'styled-components';

const sections = {
	root: styled.div`
		display: flex;
		align-items: center;
		justify-content: flex-end;

		color: #000000;
		border-top: 1px solid #000000;
		width: 46rem;

		.MuiSvgIcon-root {
			height: 3rem;
			width: 3rem;
		}
		.MuiFormControlLabel-root {
			margin-right: 4rem;
		}
		.MuiFormControlLabel-label {
			font-size: 2rem;
		}

		@media only screen and (max-width: 420px) {
			width: 100%;

			.MuiFormGroup-row {
				flex-direction: column;
			}
		}
	`,
	label: styled.div`
		font-size: 2.5rem;
		font-weight: 500;
		margin-right: 4rem;
		position: relative;
		bottom: 2px;
	`,
};

export const PaymentType = props => {
	const { onChange } = props;
	const [value, setValue] = useState('PLASTIC_CARD');

	const handleChange = event => {
		setValue(event.target.value);
		if (onChange) onChange(event.target.value);
	};

	return (
		<sections.root>
			<sections.label>Pay By:</sections.label>
			<FormControl component="fieldset">
				<RadioGroup
					aria-label="position"
					name="position"
					value={value}
					onChange={handleChange}
					row
				>
					<FormControlLabel
						value="PLASTIC_CARD"
						control={
							<Radio className="styled-radio" color="default" />
						}
						label="Credit Card"
						labelPlacement="end"
					/>
					<FormControlLabel
						value="ACH"
						control={
							<Radio className="styled-radio" color="default" />
						}
						label="E-Check"
						labelPlacement="end"
					/>
				</RadioGroup>
			</FormControl>
		</sections.root>
	);
};
