import axios from 'axios';

import { PaymentApi, RenewalApi } from 'generated-api/renewal-api';

import { apiPaths } from 'constants/api';

const {
	_env_: { REACT_APP_BASE_API_URL },
} = window;

/**
 * Service calls related to retrieving renewal details and initating
 * payment flows through pay.gov
 */
export default class BillService {
	static async getBill({ policyNumber, policyZipCode }) {
		const client = axios.create();

		const api = new RenewalApi(
			undefined,
			`${REACT_APP_BASE_API_URL}${apiPaths.RENEWAL}`,
			client,
		);

		const response = await api.findRenewalBill(policyNumber, policyZipCode);
		return response;
	}

	static async payBill({
		selectedCoverage: {
			optionNumber: selectedRenewalOption,
			totalPremium: amount,
		},
		email,
		paymentType,
		renewalId,
		successUrl,
		cancelUrl,
	}) {
		const client = axios.create();

		const api = new PaymentApi(
			undefined,
			`${REACT_APP_BASE_API_URL}${apiPaths.RENEWAL}`,
			client,
		);

		const response = await api.makePayment({
			amount,
			email,
			paymentType,
			renewalId,
			selectedRenewalOption,
			successUrl,
			cancelUrl,
		});
		return response;
	}
}
