import React, { useState, useCallback, useEffect } from 'react';

import styled from 'styled-components';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import BugReportIcon from '@material-ui/icons/BugReport';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

const sections = {
	root: styled.div`
		border-radius: 4px;
		box-shadow: 0 10px 5px rgba(0, 0, 0, 0.33);
		display: flex;
		top: -999px;
		left: 0px;

		font-size: 2rem;
		margin: 0 0 20px 0;
		max-width: 400px;
		opacity: 1;
		padding: 1rem 2rem;
		pointer-events: auto;
		position: relative;
		transition: all 600ms ease-in;
		z-index: 999;

		&.debug {
			background-color: #a0a0a0;
			color: #ffffff;
		}
		&.info {
			background-color: #000000;
			color: #ffffff;
		}
		&.success {
			background-color: green;
			color: #ffffff;
		}
		&.warn {
			background-color: salmon;
			color: #ffffff;
		}
		&.visible {
			top: 1px;
			left: 0px;
			transition: all 500ms ease-out;
		}
		&.dismissed {
			left: -999px;
			opacity: 0;
			transition: all 500ms ease-out;
		}

		svg {
			font-size: 3rem !important;
		}
	`,
	bugIcon: styled(BugReportIcon)`
		color: #ffffff;
		font-size: 2em;
	`,
	closeIcon: styled(CloseIcon)`
		color: #e5e5e5;
		cursor: pointer;
		margin-left: 2rem;
		position: relative;
		left: 1rem;
	`,
	content: styled.div`
		display: flex;
		align-items: flex-start;
	`,
	contentColumn: styled.div`
		display: flex;
		flex-direction: column;
		margin-left: 12px;
	`,
	checkIcon: styled(CheckIcon)``,
	errorIcon: styled(ErrorOutlineIcon)``,
	priorityIcon: styled(PriorityHighIcon)`
		color: #a0a0a0;
		font-size: 1em;
		font-weight: 400;
	`,
	message: styled.p`
        font-size: 0.8em;
        margin: 0 0 6px 0;

        &.info {
            color: #a0a0a0,
        &.warn {
            color: #a0a0a0;
        }
        &.success {
            color: #a0a0a0;
        }
    `,
	title: styled.h5`
		font-size: 0.8em;
		font-weight: 700;
		margin: 0 0 4px 0;
	`,
};

export const Notification = props => {
	const [isVisible, setIsVisible] = useState(false);
	const [isDismissed, setIsDismissed] = useState(false);

	const {
 level, message, onClose, stayVisible = false, title,
} = props;

	const handleClose = useCallback(() => {
		setIsDismissed(true);

		setTimeout(() => {
			if (onClose) onClose(props);
		}, 300);
	}, [onClose, props]);

	useEffect(() => {
		setTimeout(() => setIsVisible(true), 100);
	}, []);

	useEffect(() => {
		if (isVisible && !stayVisible) {
			setTimeout(() => handleClose(), 6000);
		}
	}, [isVisible, stayVisible, handleClose]);

	const renderDebug = () => (
		<sections.content>
			<sections.bugIcon />
			<sections.contentColumn>
				{title && <sections.title>{title}</sections.title>}
				<sections.message className={`${level}`}>
					{message}
				</sections.message>
			</sections.contentColumn>
		</sections.content>
	);
	const renderInfo = () => (
		<sections.content>
			<sections.priorityIcon />
			<sections.contentColumn>
				{title && <sections.title>{title}</sections.title>}
				<sections.message className={`${level}`}>
					{message}
				</sections.message>
			</sections.contentColumn>
		</sections.content>
	);
	const renderSuccess = () => (
		<sections.content>
			<sections.checkIcon />
			<sections.contentColumn>
				{title && <sections.title>{title}</sections.title>}
				<sections.message className={`${level}`}>
					{message}
				</sections.message>
			</sections.contentColumn>
		</sections.content>
	);
	const renderWarning = () => (
		<sections.content>
			<sections.errorIcon />
			<sections.contentColumn>
				{title && <sections.title>{title}</sections.title>}
				<sections.message className={`${level}`}>
					{message}
				</sections.message>
			</sections.contentColumn>
		</sections.content>
	);
	return (
		<sections.root
			className={`${level} ${isVisible ? 'visible' : ''} ${
				isDismissed ? 'dismissed' : ''
			}`}
		>
			{level && level === 'warn' && renderWarning()}
			{level && level === 'debug' && renderDebug()}
			{level && level === 'info' && renderInfo()}
			{level && level === 'success' && renderSuccess()}
			<sections.closeIcon onClick={handleClose} />
		</sections.root>
	);
};
