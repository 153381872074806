import { combineReducers } from 'redux';

import app from 'state/actions/app.actions';
import bill from 'state/actions/bill.actions';
import error from 'state/actions/error.actions';
import notification from 'state/actions/notification.actions';
import pay from 'state/actions/pay.actions';

const rootReducer = combineReducers({
	app,
	bill,
	error,
	notification,
	pay,
});
export default rootReducer;
