import React from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';

import { Notification } from 'ui-kit/notification/notification.component';
import { actions } from 'state/actions/notification.actions';
import store from 'state/store';

export const NotificationSystemStore = {
	addNotification: notification =>
		store.dispatch(actions.addNotification(notification)),
	removeNotification: notification =>
		store.dispatch(actions.removeNotification(notification)),
};

export const NotificationLevel = {
	Debug: 'debug',
	Info: 'info',
	Success: 'success',
	Warn: 'warn',
};

const sections = {
	root: styled.div`
		display: flex;
		flex-direction: column;
		align-items: center;
		align-content: center;
		justify-content: center;

		height: 100%;
		left: 0;
		pointer-events: none;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		zindex: 9999;
	`,
};

const mapStateToProps = state => ({
	notifications: state.notification.notifications,
});

export const NotificationSystem = connect(
	mapStateToProps,
	null,
)(props => {
	const { notifications } = props;
	return (
		<sections.root className="notification-system">
			{notifications &&
				notifications.length > 0 &&
				notifications.map(n => (
					<Notification
						key={n.id}
						id={n.id}
						level={n.level}
						message={n.message}
						onClose={notification => {
							NotificationSystemStore.removeNotification(
								notification,
							);
							if (n.onClose) n.onClose(notification);
						}}
						stayVisible={n.stayVisible}
						title={n.title}
					/>
				))}
		</sections.root>
	);
});
