import 'react-app-polyfill/stable';
import 'intersection-observer';

import React from 'react';
import ReactDOM from 'react-dom';
import {
	Redirect,
	Route,
	BrowserRouter as Router,
	Switch,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from 'serviceWorker';

import store from 'state/store';

import { NotificationSystem } from 'connected-components/notification-system/notification-system.component';

import App from 'components/app/app.component';
import { Footer } from 'components/footer/footer.component';

import { Cancel } from 'views/cancel/cancel.component';
import { Pay } from 'views/pay/pay.component';
import { Bill } from 'views/bill/bill.component';
import { Paid } from 'views/paid/paid.component';

import './index.css';

ReactDOM.render(
	<Provider store={store}>
		<div id="app-root">
			<App>
				<NotificationSystem />
				<Router>
					<Switch>
						<Route exact path="/pay/cancel" component={Cancel} />
						<Route exact path="/pay" component={Pay} />
						<Route exact path="/pay/bill" component={Bill} />
						<Route exact path="/payment-pending" component={Paid} />
						<Redirect from="*" to="/pay" />
					</Switch>
					<Footer />
				</Router>
			</App>
		</div>
	</Provider>,
	document.getElementById('root'),
);

serviceWorker.unregister();
