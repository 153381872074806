import { call, put, takeLatest } from 'redux-saga/effects';

import { actionTypes } from 'state/actions/bill.actions';
import { actions } from 'state/actions/app.actions';

import BillService from 'services/bill.service';

import {
	NotificationLevel,
	NotificationSystemStore
} from 'connected-components/notification-system/notification-system.component';
import {
	ErrorSystemStore,
	ErrorLevel
} from 'connected-components/error-system/error-system.component';

function* getBill(action) {
	yield put(actions.showLoading(true));

	try {
		const response = yield call(BillService.getBill, action.payload);
		if (!response.error) {
			//	check payment payment is allowed
			if (!response.data.paymentAllowed) {
				NotificationSystemStore.addNotification({
					level: NotificationLevel.Info,
					message:
						'A payment transaction for the requested bill is already in progress.',
					title: 'Bill Search'
				});
			} else {
				yield put({
					type: actionTypes.GET_BILL_COMPLETE,
					payload: {
						...response.data,
						policyZipCode: action.payload.policyZipCode
					}
				});
			}
		} else {
			throw response.error;
		}
	} catch (err) {
		if (err && err.toString().includes('404')) {
			ErrorSystemStore.addError({
				level: ErrorLevel.Info,
				message:
					'We were unable to locate a bill matching those details',
				stayVisible: true
			});
		}
		if (err && err.toString().includes('500')) {
			ErrorSystemStore.addError({
				level: ErrorLevel.Critical,
				message:
					'An unexpected error was encountered. Please try again later.',
				stayVisible: true
			});
		}
	}
	yield put(actions.showLoading(false));
}

function* payBill(action) {
	yield put(actions.showLoading(true));

	let response;
	try {
		const {
			policyNumber,
			policyZipCode,
			cancelUrl,
			renewalId,
			successUrl
		} = action.payload;

		response = yield call(BillService.payBill, {
			...action.payload,
			cancelUrl: cancelUrl
				.replace('POLICY_NUM', policyNumber)
				.replace('POLICY_ZIP', policyZipCode)
				.replace('RENEWAL_ID', renewalId),
			successUrl: successUrl
				.replace('POLICY_NUM', policyNumber)
				.replace('POLICY_ZIP', policyZipCode)
		});

		if (response && !response.error) {
			yield put({
				type: actionTypes.PAY_BILL_STARTED,
				payload: response.data
			});
		} else {
			if (response.status === 401) {
				NotificationSystemStore.addNotification({
					level: NotificationLevel.Warn,
					message:
						'A payment for this bill has already been submitted!',
					title: 'Make Payment'
				});
			}
			if (response.status === 404) {
				NotificationSystemStore.addNotification({
					level: NotificationLevel.Warn,
					message: 'This bill is no longer eligible for renewal.',
					title: 'Bill Search'
				});
			}
			throw response.error;
		}
	} catch (err) {
		if (err && err.toString().includes('400')) {
			NotificationSystemStore.addNotification({
				level: NotificationLevel.Warn,
				message: 'A payment for this bill has already been submitted!',
				title: 'Make Payment'
			});
		}
		if (err && err.toString().includes('404')) {
			NotificationSystemStore.addNotification({
				level: NotificationLevel.Warn,
				message: 'This bill is no longer eligible for renewal.',
				title: 'Bill Search'
			});
		}
	}

	yield put(actions.showLoading(false));
}

export default function* billSaga() {
	yield takeLatest(actionTypes.GET_BILL, getBill);
	yield takeLatest(actionTypes.PAY_BILL, payBill);
}
