// tslint:disable
/**
 * Policy renewal service
 * Services to support the policy renewal flow
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Signal that a transaction has been cancelled
 * @export
 * @interface CancelTransactionRequest
 */
export interface CancelTransactionRequest {
    /**
     * The pay.gov token for this payment
     * @type {string}
     * @memberof CancelTransactionRequest
     */
    token: string;
}
/**
 * Signal that a transaction has been completed
 * @export
 * @interface CompleteTransactionRequest
 */
export interface CompleteTransactionRequest {
    /**
     * The pay.gov token for this payment
     * @type {string}
     * @memberof CompleteTransactionRequest
     */
    token: string;
}
/**
 * Request to start a new payment
 * @export
 * @interface MakePaymentRequest
 */
export interface MakePaymentRequest {
    /**
     * The renewal process id
     * @type {string}
     * @memberof MakePaymentRequest
     */
    renewalId: string;
    /**
     * The selected renewal option
     * @type {number}
     * @memberof MakePaymentRequest
     */
    selectedRenewalOption: number;
    /**
     * The amount a user is willing to pay
     * @type {number}
     * @memberof MakePaymentRequest
     */
    amount: number;
    /**
     * 
     * @type {PaymentType}
     * @memberof MakePaymentRequest
     */
    paymentType: PaymentType;
    /**
     * Our url to redirect user after a successful payment
     * @type {string}
     * @memberof MakePaymentRequest
     */
    successUrl?: string;
    /**
     * Our url to redirect user after a cancelled payment
     * @type {string}
     * @memberof MakePaymentRequest
     */
    cancelUrl?: string;
    /**
     * Email of the insured. Optional. If provided, payment notification will be sent to it.
     * @type {string}
     * @memberof MakePaymentRequest
     */
    email?: string;
}
/**
 * The information needed to make a payment
 * @export
 * @interface MakePaymentResponse
 */
export interface MakePaymentResponse {
    /**
     * The transaction id
     * @type {string}
     * @memberof MakePaymentResponse
     */
    id?: string;
    /**
     * The page where user can enter their payment information
     * @type {string}
     * @memberof MakePaymentResponse
     */
    paymentPageUrl?: string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    code: number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    message: string;
}
/**
 * The payment type
 * @export
 * @enum {string}
 */
export enum PaymentType {
    PLASTICCARD = 'PLASTIC_CARD',
    ACH = 'ACH'
}

/**
 * 
 * @export
 * @interface RenewalBillResponse
 */
export interface RenewalBillResponse {
    /**
     * 
     * @type {string}
     * @memberof RenewalBillResponse
     */
    renewalId?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalBillResponse
     */
    insuredName?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalBillResponse
     */
    propertyAddressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalBillResponse
     */
    propertyAddressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalBillResponse
     */
    propertyCity?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalBillResponse
     */
    propertyStateCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalBillResponse
     */
    propertyPostalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalBillResponse
     */
    policyNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalBillResponse
     */
    policyStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalBillResponse
     */
    policyEndDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RenewalBillResponse
     */
    paymentAllowed?: boolean;
    /**
     * 
     * @type {Array<RenewalOptions>}
     * @memberof RenewalBillResponse
     */
    renewalOptions?: Array<RenewalOptions>;
}
/**
 * 
 * @export
 * @interface RenewalBillResponseAllOf
 */
export interface RenewalBillResponseAllOf {
    /**
     * 
     * @type {boolean}
     * @memberof RenewalBillResponseAllOf
     */
    paymentAllowed?: boolean;
    /**
     * 
     * @type {Array<RenewalOptions>}
     * @memberof RenewalBillResponseAllOf
     */
    renewalOptions?: Array<RenewalOptions>;
}
/**
 * 
 * @export
 * @interface RenewalInfo
 */
export interface RenewalInfo {
    /**
     * 
     * @type {string}
     * @memberof RenewalInfo
     */
    renewalId?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalInfo
     */
    insuredName?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalInfo
     */
    propertyAddressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalInfo
     */
    propertyAddressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalInfo
     */
    propertyCity?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalInfo
     */
    propertyStateCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalInfo
     */
    propertyPostalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalInfo
     */
    policyNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalInfo
     */
    policyStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalInfo
     */
    policyEndDate?: string;
}
/**
 * The renewal options
 * @export
 * @interface RenewalOptions
 */
export interface RenewalOptions {
    /**
     * total premium
     * @type {number}
     * @memberof RenewalOptions
     */
    totalPremium?: number;
    /**
     * total building coverage
     * @type {number}
     * @memberof RenewalOptions
     */
    totalBuildingCoverage?: number;
    /**
     * total contents coverage
     * @type {number}
     * @memberof RenewalOptions
     */
    totalContentsCoverage?: number;
    /**
     * building deductible
     * @type {number}
     * @memberof RenewalOptions
     */
    buildingDeductible?: number;
    /**
     * content deductible
     * @type {number}
     * @memberof RenewalOptions
     */
    contentDeductible?: number;
    /**
     * optionNumber
     * @type {number}
     * @memberof RenewalOptions
     */
    optionNumber?: number;
}
/**
 * 
 * @export
 * @interface RenewalPaymentStatus
 */
export interface RenewalPaymentStatus {
    /**
     * Renewal payment status
     * @type {string}
     * @memberof RenewalPaymentStatus
     */
    paymentStatus?: RenewalPaymentStatusPaymentStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RenewalPaymentStatusPaymentStatusEnum {
    PROCESSING = 'PROCESSING',
    SUCCESS = 'SUCCESS',
    FAIL = 'FAIL'
}

/**
 * 
 * @export
 * @interface RenewalTransactionInfo
 */
export interface RenewalTransactionInfo {
    /**
     * 
     * @type {string}
     * @memberof RenewalTransactionInfo
     */
    renewalId?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalTransactionInfo
     */
    insuredName?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalTransactionInfo
     */
    propertyAddressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalTransactionInfo
     */
    propertyAddressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalTransactionInfo
     */
    propertyCity?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalTransactionInfo
     */
    propertyStateCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalTransactionInfo
     */
    propertyPostalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalTransactionInfo
     */
    policyNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalTransactionInfo
     */
    policyStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalTransactionInfo
     */
    policyEndDate?: string;
    /**
     * Renewal payment status
     * @type {string}
     * @memberof RenewalTransactionInfo
     */
    paymentStatus?: RenewalTransactionInfoPaymentStatusEnum;
    /**
     * The transaction id
     * @type {string}
     * @memberof RenewalTransactionInfo
     */
    id?: string;
    /**
     * The tenant code
     * @type {string}
     * @memberof RenewalTransactionInfo
     */
    tenantCode?: string;
    /**
     * The application id
     * @type {string}
     * @memberof RenewalTransactionInfo
     */
    applicationId?: string;
    /**
     * The transaction amount
     * @type {number}
     * @memberof RenewalTransactionInfo
     */
    amount?: number;
    /**
     * 
     * @type {TransactionStatus}
     * @memberof RenewalTransactionInfo
     */
    status?: TransactionStatus;
    /**
     * 
     * @type {PaymentType}
     * @memberof RenewalTransactionInfo
     */
    paymentType?: PaymentType;
    /**
     * The time of transaction
     * @type {Date}
     * @memberof RenewalTransactionInfo
     */
    transactedAt?: Date;
    /**
     * The approval code
     * @type {string}
     * @memberof RenewalTransactionInfo
     */
    approvalCode?: string;
    /**
     * The account holder name
     * @type {string}
     * @memberof RenewalTransactionInfo
     */
    accountHolderName?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RenewalTransactionInfoPaymentStatusEnum {
    PROCESSING = 'PROCESSING',
    SUCCESS = 'SUCCESS',
    FAIL = 'FAIL'
}

/**
 * The transaction details
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * The transaction id
     * @type {string}
     * @memberof Transaction
     */
    id?: string;
    /**
     * The tenant code
     * @type {string}
     * @memberof Transaction
     */
    tenantCode?: string;
    /**
     * The application id
     * @type {string}
     * @memberof Transaction
     */
    applicationId?: string;
    /**
     * The transaction amount
     * @type {number}
     * @memberof Transaction
     */
    amount?: number;
    /**
     * 
     * @type {TransactionStatus}
     * @memberof Transaction
     */
    status?: TransactionStatus;
    /**
     * 
     * @type {PaymentType}
     * @memberof Transaction
     */
    paymentType?: PaymentType;
    /**
     * The time of transaction
     * @type {Date}
     * @memberof Transaction
     */
    transactedAt?: Date;
    /**
     * The approval code
     * @type {string}
     * @memberof Transaction
     */
    approvalCode?: string;
    /**
     * The account holder name
     * @type {string}
     * @memberof Transaction
     */
    accountHolderName?: string;
}
/**
 * The transaction status
 * @export
 * @enum {string}
 */
export enum TransactionStatus {
    UNKNOWN = 'UNKNOWN',
    USERCANCELLED = 'USER_CANCELLED',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    RECEIVED = 'RECEIVED',
    SETTLED = 'SETTLED',
    CANCELLED = 'CANCELLED',
    RETIRED = 'RETIRED'
}

/**
 * Request the status of transaction
 * @export
 * @interface TransactionStatusRequest
 */
export interface TransactionStatusRequest {
    /**
     * The token for this payment
     * @type {string}
     * @memberof TransactionStatusRequest
     */
    token: string;
}

/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Call this API when the user cancelled this transaction
         * @param {CancelTransactionRequest} cancelTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTransaction(cancelTransactionRequest: CancelTransactionRequest, options: any = {}): RequestArgs {
            // verify required parameter 'cancelTransactionRequest' is not null or undefined
            if (cancelTransactionRequest === null || cancelTransactionRequest === undefined) {
                throw new RequiredError('cancelTransactionRequest','Required parameter cancelTransactionRequest was null or undefined when calling cancelTransaction.');
            }
            const localVarPath = `/renewals/cancelTransaction`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof cancelTransactionRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(cancelTransactionRequest !== undefined ? cancelTransactionRequest : {}) : (cancelTransactionRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Call this API when the transaction is completed
         * @param {CompleteTransactionRequest} completeTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeTransaction(completeTransactionRequest: CompleteTransactionRequest, options: any = {}): RequestArgs {
            // verify required parameter 'completeTransactionRequest' is not null or undefined
            if (completeTransactionRequest === null || completeTransactionRequest === undefined) {
                throw new RequiredError('completeTransactionRequest','Required parameter completeTransactionRequest was null or undefined when calling completeTransaction.');
            }
            const localVarPath = `/renewals/completeTransaction`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof completeTransactionRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(completeTransactionRequest !== undefined ? completeTransactionRequest : {}) : (completeTransactionRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return the information needed to redirect user to the payment page
         * @param {MakePaymentRequest} paymentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makePayment(paymentRequest: MakePaymentRequest, options: any = {}): RequestArgs {
            // verify required parameter 'paymentRequest' is not null or undefined
            if (paymentRequest === null || paymentRequest === undefined) {
                throw new RequiredError('paymentRequest','Required parameter paymentRequest was null or undefined when calling makePayment.');
            }
            const localVarPath = `/renewals/makePayment`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof paymentRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(paymentRequest !== undefined ? paymentRequest : {}) : (paymentRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This is a webhook that will be called by payments when a payment is complete
         * @param {Transaction} completeTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyPaymentCompleted(completeTransactionRequest: Transaction, options: any = {}): RequestArgs {
            // verify required parameter 'completeTransactionRequest' is not null or undefined
            if (completeTransactionRequest === null || completeTransactionRequest === undefined) {
                throw new RequiredError('completeTransactionRequest','Required parameter completeTransactionRequest was null or undefined when calling notifyPaymentCompleted.');
            }
            const localVarPath = `/renewals/notifyPaymentCompleted`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof completeTransactionRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(completeTransactionRequest !== undefined ? completeTransactionRequest : {}) : (completeTransactionRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Call this API to get the transaction info and status
         * @param {TransactionStatusRequest} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionStatus(status: TransactionStatusRequest, options: any = {}): RequestArgs {
            // verify required parameter 'status' is not null or undefined
            if (status === null || status === undefined) {
                throw new RequiredError('status','Required parameter status was null or undefined when calling transactionStatus.');
            }
            const localVarPath = `/renewals/transaction`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof status !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(status !== undefined ? status : {}) : (status || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Call this API when the user cancelled this transaction
         * @param {CancelTransactionRequest} cancelTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTransaction(cancelTransactionRequest: CancelTransactionRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RenewalTransactionInfo> {
            const localVarAxiosArgs = PaymentApiAxiosParamCreator(configuration).cancelTransaction(cancelTransactionRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Call this API when the transaction is completed
         * @param {CompleteTransactionRequest} completeTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeTransaction(completeTransactionRequest: CompleteTransactionRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RenewalTransactionInfo> {
            const localVarAxiosArgs = PaymentApiAxiosParamCreator(configuration).completeTransaction(completeTransactionRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return the information needed to redirect user to the payment page
         * @param {MakePaymentRequest} paymentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makePayment(paymentRequest: MakePaymentRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MakePaymentResponse> {
            const localVarAxiosArgs = PaymentApiAxiosParamCreator(configuration).makePayment(paymentRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary This is a webhook that will be called by payments when a payment is complete
         * @param {Transaction} completeTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyPaymentCompleted(completeTransactionRequest: Transaction, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PaymentApiAxiosParamCreator(configuration).notifyPaymentCompleted(completeTransactionRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Call this API to get the transaction info and status
         * @param {TransactionStatusRequest} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionStatus(status: TransactionStatusRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RenewalTransactionInfo> {
            const localVarAxiosArgs = PaymentApiAxiosParamCreator(configuration).transactionStatus(status, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Call this API when the user cancelled this transaction
         * @param {CancelTransactionRequest} cancelTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTransaction(cancelTransactionRequest: CancelTransactionRequest, options?: any): AxiosPromise<RenewalTransactionInfo> {
            return PaymentApiFp(configuration).cancelTransaction(cancelTransactionRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Call this API when the transaction is completed
         * @param {CompleteTransactionRequest} completeTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeTransaction(completeTransactionRequest: CompleteTransactionRequest, options?: any): AxiosPromise<RenewalTransactionInfo> {
            return PaymentApiFp(configuration).completeTransaction(completeTransactionRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Return the information needed to redirect user to the payment page
         * @param {MakePaymentRequest} paymentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makePayment(paymentRequest: MakePaymentRequest, options?: any): AxiosPromise<MakePaymentResponse> {
            return PaymentApiFp(configuration).makePayment(paymentRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary This is a webhook that will be called by payments when a payment is complete
         * @param {Transaction} completeTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyPaymentCompleted(completeTransactionRequest: Transaction, options?: any): AxiosPromise<void> {
            return PaymentApiFp(configuration).notifyPaymentCompleted(completeTransactionRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Call this API to get the transaction info and status
         * @param {TransactionStatusRequest} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionStatus(status: TransactionStatusRequest, options?: any): AxiosPromise<RenewalTransactionInfo> {
            return PaymentApiFp(configuration).transactionStatus(status, options)(axios, basePath);
        },
    };
};

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     * 
     * @summary Call this API when the user cancelled this transaction
     * @param {CancelTransactionRequest} cancelTransactionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public cancelTransaction(cancelTransactionRequest: CancelTransactionRequest, options?: any) {
        return PaymentApiFp(this.configuration).cancelTransaction(cancelTransactionRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Call this API when the transaction is completed
     * @param {CompleteTransactionRequest} completeTransactionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public completeTransaction(completeTransactionRequest: CompleteTransactionRequest, options?: any) {
        return PaymentApiFp(this.configuration).completeTransaction(completeTransactionRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Return the information needed to redirect user to the payment page
     * @param {MakePaymentRequest} paymentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public makePayment(paymentRequest: MakePaymentRequest, options?: any) {
        return PaymentApiFp(this.configuration).makePayment(paymentRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary This is a webhook that will be called by payments when a payment is complete
     * @param {Transaction} completeTransactionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public notifyPaymentCompleted(completeTransactionRequest: Transaction, options?: any) {
        return PaymentApiFp(this.configuration).notifyPaymentCompleted(completeTransactionRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Call this API to get the transaction info and status
     * @param {TransactionStatusRequest} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public transactionStatus(status: TransactionStatusRequest, options?: any) {
        return PaymentApiFp(this.configuration).transactionStatus(status, options)(this.axios, this.basePath);
    }

}


/**
 * RenewalApi - axios parameter creator
 * @export
 */
export const RenewalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Validates that the user is allowed to view policy and make a payment
         * @param {string} policyNumber The policy number
         * @param {string} zipCode The zip code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRenewalBill(policyNumber: string, zipCode: string, options: any = {}): RequestArgs {
            // verify required parameter 'policyNumber' is not null or undefined
            if (policyNumber === null || policyNumber === undefined) {
                throw new RequiredError('policyNumber','Required parameter policyNumber was null or undefined when calling findRenewalBill.');
            }
            // verify required parameter 'zipCode' is not null or undefined
            if (zipCode === null || zipCode === undefined) {
                throw new RequiredError('zipCode','Required parameter zipCode was null or undefined when calling findRenewalBill.');
            }
            const localVarPath = `/renewals/findRenewalBill`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (policyNumber !== undefined) {
                localVarQueryParameter['policyNumber'] = policyNumber;
            }

            if (zipCode !== undefined) {
                localVarQueryParameter['zipCode'] = zipCode;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RenewalApi - functional programming interface
 * @export
 */
export const RenewalApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Validates that the user is allowed to view policy and make a payment
         * @param {string} policyNumber The policy number
         * @param {string} zipCode The zip code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRenewalBill(policyNumber: string, zipCode: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RenewalBillResponse> {
            const localVarAxiosArgs = RenewalApiAxiosParamCreator(configuration).findRenewalBill(policyNumber, zipCode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RenewalApi - factory interface
 * @export
 */
export const RenewalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Validates that the user is allowed to view policy and make a payment
         * @param {string} policyNumber The policy number
         * @param {string} zipCode The zip code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRenewalBill(policyNumber: string, zipCode: string, options?: any): AxiosPromise<RenewalBillResponse> {
            return RenewalApiFp(configuration).findRenewalBill(policyNumber, zipCode, options)(axios, basePath);
        },
    };
};

/**
 * RenewalApi - object-oriented interface
 * @export
 * @class RenewalApi
 * @extends {BaseAPI}
 */
export class RenewalApi extends BaseAPI {
    /**
     * 
     * @summary Validates that the user is allowed to view policy and make a payment
     * @param {string} policyNumber The policy number
     * @param {string} zipCode The zip code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalApi
     */
    public findRenewalBill(policyNumber: string, zipCode: string, options?: any) {
        return RenewalApiFp(this.configuration).findRenewalBill(policyNumber, zipCode, options)(this.axios, this.basePath);
    }

}


