import { put, takeEvery, takeLatest } from 'redux-saga/effects';

import { actionTypes } from 'state/actions/app.actions';

function* init(action) {
	yield put({ type: actionTypes.APP_INIT_COMPLETE, payload: action.payload });
}

function* locationChange() {
	yield put({ type: actionTypes.CLOSE_MOBILE_MENU });
}

export default function* appSaga() {
	yield takeLatest(actionTypes.APP_INIT, init);
	yield takeEvery('@@router/LOCATION_CHANGE', locationChange);
}
